import React from "react"
import Hls from 'hls.js';

export default class OnlyVideo extends React.Component {
    constructor(props) {
        super(props)
        this.video = React.createRef()
    }

    componentDidMount() {
        this.props.getUrlOfVideoStream().then((url) => {
            let video = this.video.current;
            if (Hls.isSupported()) {
                let hls = new Hls();
                hls.loadSource(url);
                hls.attachMedia(video);
                hls.on(Hls.Events.MANIFEST_PARSED, function () {
                    video.play()
                });
            } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
                video.src = url;
                video.addEventListener('loadedmetadata', function () {
                    video.play()
                });
            }
        })
        document.documentElement.style.background = 'black'
    }

    componentWillUnmount() {
        document.documentElement.style.background = 'white'
    }

    render() {
        return (
            <div>
                <video ref={this.video} muted={true}
                       style={{
                           'height': '100%',
                           'object-fit': 'cover',
                           'position': 'fixed',
                           'top': '0',
                           'left': '50%',
                           'transform': 'translate(-50%, 0)',
                       }}/>
            </div>
        )
    }
}