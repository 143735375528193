export const urlParams =  new URLSearchParams(window.location.search);
export const testFeed = urlParams.get('testFeed')
export const allLive = urlParams.get('allLive') === '1'

export function isIOs() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

