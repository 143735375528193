import axios from "axios";
import {testFeed} from "./utils.js";

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export async function gameLauncher() {

    let res = await axios.get(`${testFeed || '/'}kambi-rest-api/gameLauncher2.json`)
    return res.data
}

let cache = {};

export async function punterLogin() {
    let glRes = await gameLauncher()

    while (cache[glRes['playerId']] === 'pending'){
        await timeout(300)
    }

    if (cache[glRes['playerId']]){
        return cache[glRes['playerId']]
    }

    cache[glRes['playerId']] = 'pending'

    let res = await axios.post(`${glRes['kambiPlayerApiBaseURL']}/api/v2019/${glRes['offering']}/punter/login.json` +
        `?market=${glRes['market'].toLowerCase()}` +
        `&lang=${glRes['lang']}` +
        `&channel_id=3&client_id=2&ncid=${new Date().getTime()}&settings=true`,
        {
            "punterId": glRes['playerId'], "streamingAllowed": true,
            "ticket": glRes['authtoken'], 'requestStreaming': true,
            "channel": "MOBILE", "market": glRes['market'].toLowerCase(),
            "attributes": { "fingerprintHash": "a8e6d7daf16cfb6faa21f90411c44d6d" }
        }
    )
    cache[glRes['playerId']] = res.data
    setTimeout(()=>{delete cache[glRes['playerId']]}, 2*60*1000)
    return res.data
}

export async function getStream(eventId) {
    if (!eventId) {
        return
    }
    let glRes = await gameLauncher()
    let plRes = await punterLogin(glRes)
    let playerApiRes;
    try{
        playerApiRes= await axios.get(`${glRes["kambiPlayerApiBaseURL"]}/api/v2019/${glRes["offering"]}/stream/${eventId}`,
        {
            headers: { 'Authorization': `Bearer ${plRes["token"]}` },
            params: {
                "lang": glRes['lang'],
                "market": glRes['market'].toLowerCase(),
                "client_id": "2",
                "channel_id": "3",
                "ncid": new Date().getTime()
            }
        })
    }catch{
        console.log("Stream is not available in your country")
        return ''
    }

    playerApiRes = playerApiRes.data;

    if (playerApiRes['validationUrl'].includes('.mpd') || playerApiRes['validationUrl'].includes('.m3u8')) {
        return playerApiRes['validationUrl']
    }


    let validationLink = '';
    let token;
    if (playerApiRes['partnerId'] === '13') {
        token = playerApiRes['authToken']
    } else {
        token = decodeURIComponent(playerApiRes['authToken'])
    }

    if (playerApiRes['partnerId'] === '10') {
        validationLink = `${playerApiRes['validationUrl']}${playerApiRes['id']}/stream` +
            `?auth=${token}` +
            `&operatorId=${playerApiRes['partnerId']}` +
            `&timestamp=${playerApiRes['timestamp']}`;
    } else {
        validationLink = `${playerApiRes['validationUrl']}` +
            `?partnerId=${playerApiRes['partnerId']}` +
            `&key=${token}` +
            `&eventId=${playerApiRes['id']}` +
            `&userId=usr${playerApiRes['userId']}` +
            `&ncid=${playerApiRes['timestamp']}` +
            `&audioLangs=true&audioLang=x-world`
    }
    let stream;

    // to avoid CORS
    try {
        stream = await axios.get(validationLink, { responseType: 'text' })
    } catch (e) {
        stream = await axios.post('/corsica', { url: validationLink, method: 'get' }, { responseType: 'text' })
    }

    if (stream.request.responseText === 'geoblocked') {
        throw new Error("Stream is not availabe on server's country")
    }

    if (stream.request.responseText === 'eventover') {
        throw new Error("Event is over")
    }

    let matches = stream.request.responseText.match(/http.{150,}?(?=(\]|"|>|<))/g)

    if (matches.length > 0) {
        return matches[0]
    } else {
        console.log(stream.request.responseText)
    }

}

